<section class="hero">
  <div class="hero-body">
    <section class="headerPlanes">
      <div class="overlay">
          <div class="row">
            <div class="banner">
              <p class="title has-text-white is-size-1">Página no encontrada</p>
            </div>
          </div>
      </div>
    </section>
  </div>
</section>
