import { faEnvelope, faLink, faMapMarkerAlt, faMobile } from '@fortawesome/free-solid-svg-icons';
import { Component } from '@angular/core';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  faMobile = faMobile;
  faEnvelope = faEnvelope;
  faMapMarkerAlt = faMapMarkerAlt;
  faLink = faLink;
  faFacebook = faFacebook;
  faThumbsUp = faThumbsUp;
  title = 'like-net';
}
