import { Component, OnInit } from '@angular/core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMobile, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {
  faMobileAlt = faMobileAlt;
  faEnvelope = faEnvelope;
  faWhasapp = faWhatsapp;

  constructor() { }

  ngOnInit(): void {
  }

}
