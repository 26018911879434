<section class="hero">
  <div class="hero-body">
    <section class="headerPlanes">
      <div class="overlay">
          <div class="row">
              <div class="banner">
                <p class="title has-text-white is-size-1">Tenemos los mejores planes</p>
              </div>
          </div>
      </div>
    </section>

    <div class="container mt-4">
      <div class="columns">
          <div class="column">

            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img src="../../../assets/images/basic.png" alt="Placeholder image">
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-48x48">
                      <img src="../../../assets/images/basic.png" alt="Placeholder image">
                    </figure>
                  </div>
                  <div class="media-content">
                    <p class="title is-4">Plan Básico</p>
                    <p class="subtitle is-6">50 Megas</p>
                  </div>
                </div>

                <div class="content">
                  Costo $22,40 <br> <small>*Incluido impuestos</small>

                </div>
              </div>
            </div>

            <!--
            <div class="card border-primary mb-3" style="width: 18rem;">
              <img class="card-img-top" src="../../../assets/images/basic.png"  alt="Card image cap">
              <div class="card-body">
                <h5 class="card-title">Plan Básico</h5>
                <p class="card-text">20 Megas <br> Costo $22,40 <br> <small>*Incluido impuestos</small></p>
              </div>
            </div>
        -->
      </div>

          <div class="column">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img src="../../../assets/images/normal.png" alt="Placeholder image">
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-48x48">
                      <img src="../../../assets/images/normal.png" alt="Placeholder image">
                    </figure>
                  </div>
                  <div class="media-content">
                    <p class="title is-4">Plan Normal</p>
                    <p class="subtitle is-6">60 Megas</p>
                  </div>
                </div>

                <div class="content">
                  Costo $24,64 <br> <small>*Incluido impuestos</small>

                </div>
              </div>
            </div>
            <!--
            <div class="card border-primary mb-3" style="width: 18rem;">
              <img class="card-img-top" src="../../../assets/images/normal.png"  alt="Card image cap">
              <div class="card-body">
                  <h5 class="card-title">Plan Normal</h5>
                  <p class="card-text">25 Megas <br> Costo $24,60 <br> <small>*Incluido impuestos</small></p>
              </div>
            </div>-->
          </div>

          <div class="column">
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img src="../../../assets/images/avanzado.png" alt="Placeholder image">
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-48x48">
                      <img src="../../../assets/images/avanzado.png" alt="Placeholder image">
                    </figure>
                  </div>
                  <div class="media-content">
                    <p class="title is-4">Plan Avanzado</p>
                    <p class="subtitle is-6">70 Megas</p>
                  </div>
                </div>

                <div class="content">
                  Costo $28,00 <br> <small>*Incluido impuestos</small>

                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="columns">
          <div class="column">
        <p class="title">Condiciones comerciales</p>
        </div>
      </div>
      <div class="columns">
          <div class="column">
          <p class="subtitle">Importante: <small class="text-muted">Concepto de Banda Ancha</small></p><br>

          <p>Según lo estipulado en la Resolución 216-09-CONATEL-2009</p>

          <p class="lead text-justify">
          “Es el ancho de banda suministrado a un usuario mediante una velocidad de
          transmisión de bajada (proveedor hacia usuario) mínima efectiva igual o superior a
          256 Kbps y una velocidad de transmisión de subida (usuario hacia proveedor) mínima
          efectiva igual o superior a 128 Kbps para cualquier aplicación”.
          </p>
          <br>
          <p class="subtitle">Importante: <small class="text-muted">Compartición ó canal compartido</small></p>

          <p>
          Según lo estipulado en la Resolución 216-09-CONATEL-2009
          </p>
          <blockquote class="lead text-justify">
          “Es un canal de comunicación en el que se divide el ancho de banda disponible para el
          número de usuarios que lo ocupan simultáneamente”.
          </blockquote>
          </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
            <h1 class="title">Comunícate con nosotros</h1>
            <p>
              <span class="icon has-text-info">
                <fa-icon [icon]="faMobileAlt"></fa-icon>
              </span>
              <a href="https://api.whatsapp.com/send?phone=593958894484" target="_blank">
              <span class="icon has-text-success">
                <fa-icon [icon]="faWhasapp"></fa-icon>
              </span>&nbsp;Ventas - 095 889 4484 </a>
            </p>
            <p>
              <span class="icon has-text-info">
                <fa-icon [icon]="faMobileAlt"></fa-icon>
              </span>
              <a href="https://api.whatsapp.com/send?phone=593998658964" target="_blank"><span class="icon has-text-success">
                <fa-icon [icon]="faWhasapp"></fa-icon>
              </span>&nbsp;Oficina - 099 865 8964</a>
            </p>
            <p>
              <span class="icon has-text-info">
                <fa-icon [icon]="faEnvelope"></fa-icon>
              </span>Escríbenos a likenet0619@gmail.com</p>
        </div>
      </div>
    </div>
  </div>
</section>
