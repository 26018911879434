<section class="hero">
  <div class="hero-body">
    <section class="headerPlanes">
      <div class="overlay">
          <div class="row">
            <div class="banner">
              <p class="title has-text-white is-size-1">Estamos Comprometidos con ofrecerte un servicio de calidad</p>
            </div>
          </div>
      </div>
    </section>
    <div class="container bg-grey mt-4">
      <div class="columns">
        <div class="column is-one-quarter">
          <span class="glyphicon glyphicon-globe logo slideanim">
            <img src="../../../assets/images/logo.likenet.png" style="max-height: 300px; max-width: 300px;">
          </span>
        </div>
        <div class="column">
          <p class="title is-1">Nuestros Valores</p><br>
          <h4><strong>MISIÓN:</strong> En Likenet estamos comprometidos a satisfacer las necesidades integrales de telecomunicaciones, brindando el mejor servicio de internet a nuestros usuarios
          </h4><br>
          <p><strong>VISION:</strong>
            Ser una empresa líder en el mercado de acceso a Internet, con calidad, innovación y efectividad en nuestros servicios, manteniendo así la fidelidad de nuestros clientes
          </p>



        </div>

      </div>
      <div class="columns">
        <div class="column">
          <h1 class="title">Comun&iacute;cate con nosotros</h1>
            <p>
              <span class="icon has-text-info">
                <fa-icon [icon]="faMobileAlt"></fa-icon>
              </span>
              <a href="https://api.whatsapp.com/send?phone=593959155269" target="_blank">
              <span class="icon has-text-success">
                <fa-icon [icon]="faWhasapp"></fa-icon>
              </span>&nbsp;Ventas - 095 915 5269 </a>
            </p>
            <p>
              <span class="icon has-text-info">
                <fa-icon [icon]="faMobileAlt"></fa-icon>
              </span>
              <a href="https://api.whatsapp.com/send?phone=593998658964" target="_blank"><span class="icon has-text-success">
                <fa-icon [icon]="faWhasapp"></fa-icon>
              </span>&nbsp;Oficina - 099 865 8964</a>
            </p>
            <p>
              <span class="icon has-text-info">
                <fa-icon [icon]="faEnvelope"></fa-icon>
              </span>Escríbenos a likenet0619@gmail.com</p>
        </div>
      </div>
    </div>
  </div>
</section>
