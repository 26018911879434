

<nav class="navbar is-fixed-top is-dark" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="https://like-net.net">
      <img src="../../../assets/images/logolk.png" width="112" height="28">
    </a>

    <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-start">
      <a class="navbar-item"  [routerLink]="['ui/home']">
        Home
      </a>

      <a class="navbar-item" [routerLink]="['ui/servicios']">
        Servicios
      </a>
      <a class="navbar-item" [routerLink]="['ui/planes']">
        Planes
      </a>
      <a class="navbar-item" [routerLink]="['ui/indicadores']">
        Indicadores
      </a>
      <a class="navbar-item" [routerLink]="['ui/nosotros']">
        Nosotros
      </a>
    </div>
  </div>
</nav>
