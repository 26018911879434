
<section>
  <div>


    <section class="header">
      <div class="overlay">
        <div class="row">
          <div class="banner-caption">
            <p>Tenemos los mejores planes</p>
            <p>Comunícate con nosotros</p>
            <p>Ventas - 095 889 4484</p>
            <p>Oficina - 099 865 8964</p>
            <p>Escríbenos - likenet0619@gmail.com</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>

<section class="hero">
  <div class="hero-body">
    <div class="container">
      <p class="title">Principal</p>
      <div class="columns">
        <div class="column">
          <div class="box">
            <figure class="image is-128x128">
              <img class="is-rounded" src="../../assets/images/sec.png" alt="Generic placeholder image">
            </figure>

            <p class="title">Sobre Seguridad Informatíca</p>
            <p>
              <li class="text-justify">
                Actualice regularmente su sistema operativo y el software instalado en su equipo, poniendo especial
                atención a las actualizaciones de su navegador web. A veces, los sistemas operativos presentan fallos,
                que pueden ser aprovechados por delincuentes informáticos. Frecuentemente aparecen actualizaciones que
                solucionan dichos fallos. Estar al día con las actualizaciones, así como aplicar los parches de
                seguridad recomendados por los fabricantes, le ayudará a prevenir la posible intrusión de hackers y la
                aparición de nuevos virus.
              </li><br>
              <li class="text-justify">
                Instale un Antivirus y actualícelo con frecuencia. Analice con su antivirus todos los dispositivos de
                almacenamiento de datos que utilice y todos los archivos nuevos, especialmente aquellos archivos
                descargados de internet.
              </li><br>
              <li class="text-justify">
                Instale un Firewall o Cortafuegos con el fin de restringir accesos no autorizados de Internet.
              </li><br>
              <li class="text-justify">
                Es recomendable tener instalado en su equipo algún tipo de software anti-spyware, para evitar que se
                introduzcan en su equipo programas espías destinados a recopilar información confidencial sobre el
                usuario.
              </li><br>
            </p>

          </div>
        </div><!-- /.col-lg-4 -->

        <div class="column">
          <div class="box">
            <figure class="image is-128x128">

              <img class="is-rounded" src="../../assets/images/sec2.png" alt="Generic placeholder image">
            </figure>

            <p class="title">Relacionados con la navegación en internet y la utilización del correo electrónico</p>
            <p>
              <li class="text-justify">
                Utilice contraseñas seguras, es decir, aquellas compuestas por ocho caracteres, como mínimo, y que
                combinen letras, números y símbolos. Es conveniente además, que modifique sus contraseñas con
                frecuencia. En especial, le recomendamos que cambie la clave de su cuenta de correo si accede con
                frecuencia desde equipos públicos.
              </li><br>
              <li class="text-justify">
                Navegue por páginas web seguras y de confianza. Para diferenciarlas identifique si dichas páginas tienen
                algún sello o certificado que garanticen su calidad y fiabilidad. Extreme la precaución si va a realizar
                compras online o va a facilitar información confidencial a través de internet. En estos casos reconocerá
                como páginas seguras aquellas que cumplan dos requisitos:
              </li><br>
              <li class="text-justify">
                Deben empezar por https:// en lugar de http.
              </li><br>
              <li class="text-justify">
                En la barra del navegador debe aparecer el icono del candado cerrado. A través de este icono se puede
                acceder a un certificado digital que confirma la autenticidad de la página.
              </li><br>
              <li class="text-justify">
                Sea cuidadoso al utilizar programas de acceso remoto. A través de internet y mediante estos programas,
                es posible acceder a un ordenador, desde otro situado a kilómetros de distancia. Aunque esto supone una
                gran ventaja, puede poner en peligro la seguridad de su sistema.
              </li><br>
              <li class="text-justify">
                Ponga especial atención en el tratamiento de su correo electrónico, ya que es una de las herramientas
                más utilizadas para llevar a cabo estafas, introducir virus, etc. Por ello le recomendamos que:
              </li><br>
              <li class="text-justify">
                No abra mensajes de correo de remitentes desconocidos.
              </li>
              <li class="text-justify">
                Desconfíe de aquellos e-mails en los que entidades bancarias, compañías de subastas o sitios de venta
                online, le solicitan contraseñas, información confidencial, etc.
              </li>
              <li class="text-justify">
                No propague aquellos mensajes de correo con contenido dudoso y que le piden ser reenviados a todos sus
                contactos. Este tipo de mensajes, conocidos como hoaxes, pretenden avisar de la aparición de nuevos
                virus, transmitir leyendas urbanas o mensajes solidarios, difundir noticias impactantes, etc. Estas
                cadenas de e-mails se suelen crear con el objetivo de captar las direcciones de correo de usuarios a los
                que posteriormente se les enviarán mensajes con virus, phishing o todo tipo de spam
              </li>
              <li class="text-justify">
                Utilice algún tipo de software Anti-Spam para proteger su cuenta de correo de mensajes no deseados.
              </li>
            </p>
          </div>
        </div><!-- /.col-lg-4 -->


        <div class="column">
          <div class="box">
            <figure class="image is-128x128">

              <img class="is-rounded" src="../../assets/images/sitios.png" alt="Generic placeholder image" width="140"
                height="140">
            </figure>
            <p class="title">Sitios de interes</p>

            <a href="http://www.arcotel.gob.ec">
              <img src="../../../assets/images/logo-arcotel.jpg" style="max-width: 300px;" alt="Arcotel">
            </a>
            <hr>
            <a
              href="https://www.telecomunicaciones.gob.ec/wp-content/uploads/downloads/2012/09/Resoluci%C3%B3n-TEL-477-16-CONATEL-2012.pdf">
              Reglamento de Abonados
            </a>
            <hr>
            <p class="title">Medidor de Ancho de Banda</p>
            <!--
              <figure class="image is-256x256">
                <a href="http://like-net.speedtestcustom.com/" target="_blank">
                  <img src="../../../assets/images/speedtest.jpg">
                </a>
              </figure>
-->

            <section>
              <div class="columns">
                <div class="column">

                </div>
              </div>
              <div id="bw-container" style="height: 300px;">
                <a id="sc-start-button" class="sc-button sc-uppercase" onclick="speedcheck.start()">Realizar prueba de
                  velocidad</a>
                  <canvas id="sc-ctx" height="200" width="200"></canvas>
                <div id="sc-results">
                  <div id="sc-results-ping" class="sc-result"></div>
                  <div id="sc-results-download" class="sc-result"></div>
                  <div id="sc-results-upload" class="sc-result"></div>
                  <div id="sc-footer">LikeNet</div>
                </div>
                <div id="sc-results-actions"><span id="sc-details-button" class="sc-button sc-uppercase"
                    onclick="speedcheck.restart()">Realizar prueba de velocidad</span>
                </div>
                <div id="sc-footer">
                  <span>LikeNet</span><br>
                  <a href="https://www.speedcheck.org/privacy/" rel="nofollow">Privacidad</a>
                </div>
              </div>
            </section>
          </div><!-- /.col-lg-4 -->
        </div>
      </div>


    </div>
  </div>
</section>
