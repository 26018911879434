<section class="hero">
  <div class="hero-body">
    <section class="headerPlanes">
      <div class="overlay">
        <div class="row">
          <div class="banner">
            <p class="title has-text-white is-size-1">Nuestros servicios</p>
          </div>
        </div>
      </div>
    </section>
    <div class="container bg-grey mt-4">
      <div class="columns">
        <div class="column is-one-quarter">
          <span class="glyphicon glyphicon-globe logo slideanim">
            <img src="../../../assets/images/logo.likenet.png" style="max-height: 300px; max-width: 300px;">
          </span>
        </div>
        <div class="column">
          <p class="title is-size-1">Servicios</p><br>
          <ul>
            <li>
              Acceso a la red de Redes Internet con la mejor calidad
            </li>
            <li>
              Gestión de servicios de tecnología
            </li>
            <li>
              Solución de problemas de equipo y reparaciones
            </li>
            <li>
              Redes
            </li>
            <li>
              Planes de Apoyo de Atención
            </li>
            <li>
              Soluciones de Data Backup
            </li>
            <li>
              Recuperación de Datos
            </li>
            <li>
              Soporte Remoto
            </li>
            <li>
              Servicios de Capacitación
            </li>
            <li>
              Firewalls y seguridad en Internet
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
