import { faCoffee, faEnvelope, faMobile, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-planes',
  templateUrl: './planes.component.html',
  styleUrls: ['./planes.component.scss']
})
export class PlanesComponent implements OnInit {
  faCoffee = faCoffee;
  faMobileAlt = faMobileAlt;
  faEnvelope = faEnvelope;
  faWhasapp = faWhatsapp;
  constructor() { }

  ngOnInit(): void {
  }

}
