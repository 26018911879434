import { Component, OnInit } from '@angular/core';
import {bulmaCarousel} from 'bulma-carousel';
import { SpeedTestService } from 'ng-speed-test';
declare var bulmaCarousel: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public hasTracked:boolean = false;
  public isTracking:boolean = false;
  public iterations:number = 1;
  public speeds:string[] = [];

  constructor(private speedTestService: SpeedTestService) { }

  ngOnInit(): void {
  }

  trackSpeed():void {
    if (this.hasTracked) {
      this.speeds = [];

      this.hasTracked = false;
    }

    if (this.iterations > 100) {
      this.iterations = 100;
    }

    this.isTracking = true;

    this.speedTestService.getMbps({ iterations: 1, retryDelay: 1500 }).subscribe(
      (speed) => {
        this.speeds.unshift(
          speed.toFixed(2)
        );

        if (this.speeds.length < this.iterations) {
          this.trackSpeed();
        } else {
          this.isTracking = false;
          this.hasTracked = true;
        }
      }
    )
  }
}
