import { NotFoundComponent } from './not-found/not-found.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { PlanesComponent } from './planes/planes.component';
import { IndicadoresComponent } from './indicadores/indicadores.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'home', component: HomeComponent},
  { path: 'indicadores', component: IndicadoresComponent},
  { path: 'planes', component: PlanesComponent},
  { path: 'servicios', component: ServiciosComponent},
  { path: 'nosotros', component: NosotrosComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UiRoutingModule { }
