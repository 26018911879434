// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCTmnoaOUupqumQUtD2afQ8tSB5DKlXJaY",
    authDomain: "likenet-net.firebaseapp.com",
    projectId: "likenet-net",
    storageBucket: "likenet-net.appspot.com",
    messagingSenderId: "330009312947",
    appId: "1:330009312947:web:9c82a5a1e6f4d886fd7ac6",
    measurementId: "G-2ZKLE2W7K0"
  }
};

/*
apiKey: 'AIzaSyCQRaGHkBczHkws2bRcWTVLXMfax3TNIAY',
    authDomain: 'likenet-f8505.firebaseapp.com',
    databaseURL: 'https://likenet-f8505.firebaseio.com',
    projectId: 'likenet-f8505',
    storageBucket: 'likenet-f8505.appspot.com',
    messagingSenderId: '720341821623',
    appId: '1:720341821623:web:72e735468243e98c1c4532',
    measurementId: 'G-F9JH72515P'
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
